#breadcrumb{
	position: relative;
	width: 100%;
	height: 480px;
	@media screen and (max-width: $tab){
		height: 360px;	
	}
	@media screen and (max-width: $phone){
		height: 240px;	
	}
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			background: #000;
			position: absolute;
			top: 0;
			left: 0;
			opacity: .12;
		}
	}
	h1{
		line-height: 1;
		font-size: 1.32rem;
		font-weight: 700;
		color: #FFF;
		overflow: hidden;
		position: absolute;
		left: 10vw;
		top: calc(50% + #{$pc-head/2} - 20px);
		transform: translateY(-50%);
		z-index: 2;
		@media screen and (max-width: $tab){
			top: calc(50% + #{$pc-head/2} - 32px);
		}
		@media screen and (max-width: $phone){
			font-size: 1rem;	
		}
		&:before{
			content: attr(data-eng);
			font-size: 5.66rem;
			text-transform: uppercase;
			margin: 0 0 8px;
			display: block;
			transform: translateX(-2px);
			@media screen and (max-width: $tab){
				font-size: 3.4rem;	
			}
			@media screen and (max-width: $phone){
				font-size: 2rem;	
			}
		}
	}
	
	.bread{
		position: absolute;
		bottom: 24px;
		left: 40px;
		color: #FFF;
		@media screen and (max-width: $phone){
			bottom: $sp-side;
			left: $sp-side;
			width: calc(100% - #{$sp-side * 2});
			overflow: hidden;
		}
		a{
			color: #FFF;
		}
        ol{
            @include flex();
            align-items: center;
            justify-content: flex-start;
			@media screen and (max-width: $phone){
				width: 200%;	
			}
            li{
                list-style: none;
                font-size: .72rem;
                color: $white;
                &:after{
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-size: .64rem;
                    font-weight: 900;
                    opacity: .4;
                    display: inline-block;
                    margin: 0 16px;
                    transform: translateY(-2px);
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                a{
                    color: $white;
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
						opacity: .88;
                        font-size: .88rem;
                    }
                }
            }
        }
	}
}