.site-access{
	.acc{
		@include flex();
		@media screen and (max-width: $tab){
			display: block;	
		}
		&__map{
			width: 560px;
			height: 350px;
			position: relative;
			@media screen and (max-width: $tab){
				width: 100%;
			}
			@media screen and (max-width: $phone){
				height: 200px;	
			}
			iframe{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				filter: grayscale(1);
				vertical-align: bottom;
			}
		}
		&__data{
			width: calc(100% - 560px);
			padding: 0 0 0 48px;
			@media screen and (max-width: $tab){
				width: auto;
				padding: 32px 0 0;
			}
			@media screen and (max-width: $phone){
				padding: 24px 0 0;	
			}
			&__meta{
				position: relative;
				dt{
					font-weight: 700;
					font-size: 1.32rem;
					margin: 0 0 12px;
					padding: 0 0 12px;
					border-bottom: $border 1px solid;
					@media screen and (max-width: $phone){
						font-size: 1.2rem;	
					}
				}
				dd{
					font-size: 1.08rem;
				}
				.linkmap{
					position: absolute;
					top: 0;
					right: 0;
					@media screen and (max-width: $phone){
						position: relative;
						top: 0;
						right: auto;
						left: 0;
					}
					a{
						font-size: .92rem;
						color: $normal;
						padding: 4px 20px;
						background: $gray;
						display: block;
						border-radius: 999px;
						@media screen and (max-width: $phone){
							text-align: center;
							padding: 10px 0;
							margin: 12px 0 0;
							background: darken($gray, 4%);
							border-radius: 4px;
						}
						&:hover{
							background: $green;
							color: #FFF;
							i{
								color: #FFF;
							}
						}
						i{
							color: $green;
							display: inline-block;
							margin: 0 6px 0 0;
						}
					}
				}
			}
			&__route{
				margin: 24px 0 0;
				background: $gray;
				padding: 24px;
				border-radius: 3px;
				@media screen and (max-width: $phone){
					padding: $sp-side*1.5;
					border-radius: 4px;
				}
				dt{
					font-weight: 700;
					margin: 0 0 6px;
				}
				dd{
					@media screen and (max-width: $phone){
						font-size: .96rem;	
					}
				}
			}
			.btnarea{
				margin: 28px 0 0;
				@media screen and (max-width: $tab){
					text-align: center;	
				}
			}
		}
	}
}