.site-business{
	.localnav{
		&__list{
			@include flex();
			margin: 0 0 64px;
			@media screen and (max-width: $tab){
				margin: 0 0 48px;	
			}
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
			> li{
				width: calc(100% / 6 - 2px);
				@media screen and (max-width: $tab){
					width: calc(100% / 3 - 2px);
					margin-top: 4px;
				}
				@media screen and (max-width: $phone){
					width: calc(100% / 2 - 2px);
					margin-top: 4px;
				}
				&.active{
					a{
						position: relative;
						&:before,
						&:after{
							content: '';
							width: 8px;
							height: 8px;
							position: absolute;
							top: 4px;
							left: 4px;
							background: $green;
							border-radius: 50%;
						}
						&:before{
							opacity: 0;
							animation: scl 800ms infinite;
							@keyframes scl{
								0%{
									transform: scale(1);
									opacity: 1;
								}
								100%{
									transform: scale(2);
									opacity: 0;
								}
							}
						}
					}
				}
				a{
					display: block;
					background: darken($gray, 2%);
					color: $normal;
					padding: 15px 0;
					text-align: center;
					font-size: .88rem;
					@media screen and (max-width: $phone){
						font-size: .8rem;	
					}
					&:hover{
						background: darken($gray, 6%);
					}
				}
			}
		}
	}
	
	.contact{
		margin: 64px 0 0;
		background: darken($gray, 2%);
		padding: 32px;
		@media screen and (max-width: $tab){
			margin: 40px 0 0;	
		}
		@media screen and (max-width: $phone){
			margin: 32px 0 0;
			padding: $sp-side;
		}
		dl{
			@include flex();
			justify-content: center;
			@media screen and (max-width: $phone){
				display: block;	
			}
			dt{
				font-size: 1.32rem;
				font-weight: 700;
				text-align: center;
				line-height: 1.56;
				width: 100%;
				margin: 0 0 20px;
				@media screen and (max-width: $phone){
					font-size: 1.12rem;
					margin: 0 0 12px;
				}
			}
			dd{
				margin: 0 20px 0 0;
				&:last-of-type{
					margin-right: 0;
				}
				@media screen and (max-width: $phone){
					margin: 0 0 10px;
					text-align: center;
					&:last-of-type{
						margin: 0;
					}
				}
				a{
					display: block;
					padding: 12px 64px;
					font-size: 1.16rem;
					border-radius: 4px;
					background: $white;
					color: $normal;
					box-shadow: 0 0 16px rgba($normal, .04);
					@media screen and (max-width: $tab){
						padding: 12px 32px;	
					}
					&:hover{
						@include grd();
						color: $white;
						i{
							color: $white;
						}
					}
					i{
						color: $green;
						display: inline-block;
						margin: 0 8px 0 0;
					}
				}
			}
		}
	}
	
	.busttl{
		font-size: 2.32rem;
		line-height: 1.56;
		font-weight: 700;
		text-align: center;
		margin: 0 0 32px;
		@media screen and (max-width: $phone){
			font-size: 1.56rem;
			margin: 0 0 24px;
		}
		&:before{
			content: attr(data-eng);
			display: block;
			font-size: .88rem;
			color: $normal;
			letter-spacing: .12em;
			opacity: .2;
			margin: 0 0 4px;
		}
	}
	
	.flexbox{
		@include flex();
		@media screen and (max-width: $tab){
			display: block;	
		}
		&__pic{
			width: 500px;
			@media screen and (max-width: $tab){
				width: 100%;	
			}
			img{
				width: 100%;
				height: auto;
			}
		}
		&__data{
			width: calc(100% - 500px);
			padding: 0 0 0 56px;
			@media screen and (max-width: $tab){
				width: auto;
				padding: 48px 0 0;
			}
			@media screen and (max-width: $phone){
				padding: 24px 0 0;	
			}
			dl{
				dt{
					font-size: 1.72rem;
					line-height: 1.56;
					font-weight: 700;
					margin: 0 0 20px;
					padding: 0 0 16px;
					border-bottom: $border 1px solid;
					@media screen and (max-width: $phone){
						font-size: 1.32rem;	
					}
				}
				dd{
					font-size: 1.06rem;
					text-align: justify;
					margin: 16px 0 0;
					@media screen and (max-width: $tab){
						font-size: 1.12rem;	
					}
					@media screen and (max-width: $phone){
						font-size: .96rem;	
					}
					&:first-child{
						margin-top: 0;
					}
				}
			}
		}
	}
	
	.context{
		margin: 64px 0 0;
		border: darken($gray, 2%) 8px solid;
		padding: 32px;
		font-size: 1.12rem;
		font-weight: 700;
		text-align: center;
		@media screen and (max-width: $tab){
			margin: 40px 0 0;	
		}
		@media screen and (max-width: $phone){
			margin: 32px 0 0;
			padding: $sp-side*1.5;
		}
		strong{
			font-size: 1.88rem;
			color: $green;
			line-height: 1.3;
			display: inline-block;
			margin: 0 2px;
			@media screen and (max-width: $phone){
				font-size: 1.32rem;	
			}
		}
	}
	
	.other{
		margin: 64px 0 0;
		border: darken($gray, 2%) 8px solid;
		padding: 32px;
		font-size: 1.04rem;
		@include flex();
		@media screen and (max-width: $tab){
			margin: 40px 0 0;	
		}
		@media screen and (max-width: $phone){
			margin: 32px 0 0;
			padding: $sp-side*1.5;
			display: block;
		}
		&__data{
			width: calc(100% - 300px);
			@media screen and (max-width: $phone){
				width: auto;	
			}
		}
		&__btn{
			width: 240px;
			@include flex();
			align-items: center;
			justify-content: center;
			border: $green 2px solid;
			color: darken($green, 4%);
			@media screen and (max-width: $phone){
				width: auto;
				margin: 16px 0 0;
				padding: 12px 0;
			}
			&:hover{
				background: $green;
				color: $white;
			}
		}
	}
	
	.flowbox{
		margin: 64px 0 0;
		@media screen and (max-width: $tab){
			margin: 40px 0 0;	
		}
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		&__ttl{
			font-size: 1.44rem;
			font-weight: 700;
			padding: 10px 0;
			background: $green;
			color: $white;
			text-align: center;
			position: relative;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				font-size: 1.2rem;
				margin: 0 0 24px;
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 16px 16px 0 16px;
				border-color: $green transparent transparent transparent;
				position: absolute;
				left: 50%;
				bottom: -16px;
				transform: translateX(-50%);
			}
		}
		&__txt{
			text-align: center;
			font-size: 1.12rem;
			@media screen and (max-width: $phone){
				font-size: 1rem;
				text-align: left;
			}
			strong{
				font-weight: 700;
				color: $orange;
			}
		}
		
		&__mini{
			margin: 32px 0 0;
			border: darken($gray, 2%) 8px solid;
			padding: 32px;
			@media screen and (max-width: $phone){
				padding: 0;
				margin: 24px 0 0;
				border: 0;
			}
			h4{
				font-size: 1.56rem;
				font-weight: 700;
				border-bottom: $border 1px solid;
				padding: 0 0 12px;
				margin: 0 0 20px;
				@media screen and (max-width: $phone){
					font-size: 1.2rem;	
				}
			}
			&__list{
				li{
					background: $gray;
					padding: 32px;
					position: relative;
					margin: 0 0 24px;
					@include flex();
					@media screen and (max-width: $phone){
						padding: $sp-side*1.5;
						display: block;
					}
					@for $i from 1 through 10{
						&:nth-child(#{$i}){
							&:before{
								content: '#{$i}';
								position: absolute;
								top: 0;
								left: 0;
								width: 48px;
								height: 48px;
								font-size: 1.32rem;
								font-weight: 700;
								background: $green;
								color: $white;
								border-radius: 0 0 8px 0;
								@include flex();
								align-items: center;
								justify-content: center;
							}
						}
					}
					&:last-child{
						margin: 0;
					}
					> figure{
						width: 160px;
						@media screen and (max-width: $phone){
							width: 100%;	
							height: calc(calc(100vw - #{$sp-side*2}) * 9 / 16);
						}
						img{
							width: 100%;
							@media screen and (max-width: $phone){
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}
					}
					dl{
						width: calc(100% - 160px);
						padding: 0 0 0 32px;
						@media screen and (max-width: $phone){
							width: auto;
							padding: 24px 0 0;
						}
						dt{
							font-size: 1.24rem;
							font-weight: 700;
							margin: 0 0 6px;
						}
						dd{
							margin: 0 0 12px;
							@media screen and (max-width: $phone){
								font-size: .96rem;	
							}
							&:last-of-type{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}