#site-header{
	//background: $white;
	position: fixed;
	width: 100%;
	height: $pc-head;
	top: 0;
	left: 0;
	@include flex();
	align-items: center;
	justify-content: center;
	z-index: 1000;
	color: $white;
	@media screen and (max-width: $phone){
		height: $sp-head;	
	}
	&:before{
		content: '';
		background: rgba($white,.96);	
		width: 100%;
		height: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: height 400ms $ease;
	}
	*{
		transition: color 200ms $ease;
	}
	a{
		color: $white;
	}
	&.active,
	&:hover{
		color: $normal;
		&:before{
			height: 100%;
			box-shadow: 0 0 24px rgba($normal,.08);
		}
		a{
			color: $normal;
		}
		.contact{
			a{
				border-color: rgba($white,0);
				&:before{
					opacity: 1;
				}
			}
		}
	}
	.logo{
		position: absolute;
		top: 50%;
		left: 40px;
		transform: translateY(-50%);
		white-space: nowrap;
		line-height: 1;
		@media screen and (max-width: $tab){
			z-index: 200;
			&.active{
				color: $normal;
			}
		}
		@media screen and (max-width: $phone){
			left: $sp-side;	
		}
		h1{
			position: absolute;
			top: 2px;
			left: 43px;
			font-size: .68rem;
			@media screen and (max-width: $phone){
				font-size: .6rem;
				left: 36px;
			}
		}
		&__pic{
			@include flex();
			align-items: flex-end;
			justify-content: flex-start;
			img{
				width: 28px;
				@media screen and (max-width: $phone){
					width: 24px;	
				}
			}
			figcaption{
				font-size: 1.2rem;
				font-weight: 700;
				margin: 0 0 1px 14px;
				@media screen and (max-width: $phone){
					font-size: 1rem;
					margin: 0 0 0 12px;
				}
			}
		}
	}
	.gnavi{
		margin: 2px 0 0 28px;
		@media screen and (min-width: $tab + 1px){
			display: block !important;
		}
		@media screen and (max-width: $tab){
			position: fixed;
			width: 100%;
			min-height: 100%;
			background: #FFF;
			top: 0;
			left: 0;
			margin: 0;
			padding-top: $pc-head;
			z-index: 100;
			pointer-events: none;
			> *{
				visibility: hidden;
			}
			&.active{
				background: #FFF;
				pointer-events: auto;
				* {
					visibility: visible;
				}
			}
		}
		&__list{
			@include flex();
			align-items: center;
			justify-content: flex-start;
			@media screen and (max-width: $tab){
				display: block;
				width: 100%;
				height: calc(100% - #{$pc-head});
				position: absolute;
				top: $pc-head;
				left: 0;
				overflow: auto;
				border-top: $border 1px solid;
			}
			@media screen and (max-width: $phone){
				top: $sp-head;
				height: calc(100% - #{$sp-head});
			}
			> li{
				margin: 0 0 0 3.6vw;
				&.sp{
					display: none;
					@media screen and (max-width: $tab){
						display: block;	
					}
				}
				@media screen and (max-width: $tab){
					margin: 0;
					border-bottom: $border 1px solid;
				}
				&:first-child{
					margin-left: 0;
				}
				> a{
					font-size: 1.04rem;
					position: relative;
					@media screen and (max-width: $tab){
						color: $normal;
						font-size: 1.1rem;
						display: block;
						padding: 20px 40px;
					}
					@media screen and (max-width: $phone){
						padding: $sp-side;	
					}
					&:hover{
						color: darken($green, 6%);
						i{
							transform: rotate(-180deg);
							color: darken($green, 6%);
							@media screen and (max-width: $tab){
								transform: translateY(-50%);
							}
						}
					}
					i.sp{
						display: none;
						@media screen and (max-width: $tab){
							display: inline-block;
						}
					}
                    i{
                        display: inline-block;
                        margin: 0 0 0 8px;
						transition: all 200ms $ease;
						@media screen and (max-width: $tab){
							color: darken($border, 16%);
							position: absolute;
							top: 50%;
							right: 40px;
							transform: translateY(-50%);
						}
						@media screen and (max-width: $phone){
							right: $sp-side;	
						}
                    }
				}
			}
			@media screen and (min-width: $tab + 1px){
				.subnavi{
					.clone{
						position: relative;
					}
					&:hover{
						.clone{
							&:before{
								content: '';
								width: 100%;
								height: $pc-head;
								position: absolute;
								top: 0;
								left: 0;
							}
							+ .sub{
								opacity: 1;
								transform: translateY(0);
								pointer-events: auto;
							}
						}
					}
				}
				.sub{
					position: fixed;
					top: $pc-head;
					padding: 40px 0;
					left: 0;
					width: 100vw;
					background: $white;
					border-top: $border 1px solid;
					box-shadow: 0 32px 32px rgba(darken($green, 24%), .12);
					opacity: 0;
					pointer-events: none;
					transform: translateY(-20px);
					transition: all 400ms $ease;
					transition-property: opacity, transform;
					ul{
						@include flex();
						li{
							width: 16.6%;
							text-align: center;
							border-right: $border 1px solid;
							&:last-child{
								border: none;
							}
							a{
								display: block;
								transition: opacity 100ms $ease;
								&:hover{
									opacity: .64;
								}
							}
							figure{
								img{
									width: 48px;
									opacity: .92;
								}
								figcaption{
									margin: 16px 0 0;
									line-height: 1;
									font-size: .8rem;
								}
							}
						}
					}
				}
			}
			@media screen and (max-width: $tab){
				.subnavi{
					.sub{
						ul{
							@include flex();
							padding: 40px;
							padding-top: 0;
							@media screen and (max-width: $phone){
								padding: $sp-side;
								padding-top: 0;
							}
						}
						li{
							width: 32%;
							margin-top: 2%;
							height: auto;
							&:nth-child(-n+3){
								margin-top: 0;
							}
							@media screen and (max-width: $phone){
								width: 48.5%;
								margin-top: 3%;
								&:nth-child(-n+3){
									margin-top: 3%;
								}
								&:nth-child(-n+2){
									margin-top: 0;
								}
							}
							a{
								@include flex();
								align-items: center;
								height: 100%;
								color: $normal;
								background: $gray;
								padding: 20px 16px;
								border-radius: 4px;
								@media screen and (max-width: $phone){
									padding: $sp-side;	
								}
								figure{
									text-align: center;
									img{
										width: 30%;
										@media screen and (max-width: $phone){
											width: 25%;
										}
									}
									figcaption{
										font-size: .96rem;
										margin: 8px 0 0;
										@media screen and (max-width: $phone){
											font-size: .88rem;	
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.contact{
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translateY(-50%);
		@media screen and (max-width: $tab){
			display: none;
		}
		a{
			border: $white 1px solid;
            color: $white;
            padding: 8px 32px;
			border-radius: 999px;
            @include flex();
            align-items: center;
			overflow: hidden;
			position: relative;
			transition: all 200ms $ease;
			transition-property: box-shadow, border; 
			&:before{
				content: '';
				width: 100%;
				height: 100%;
				@include grd();
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				opacity: 0;
				transition: opacity 200ms $ease;
			}
			&:hover{
				border-color: $blue;
				box-shadow: 0 0 12px rgba($green, .56);
			}
            i{
                display: inline-block;
                margin: 0 8px 0 0;
            }
		}
	}
	
	.trg{
		display: none;
		@media screen and (max-width: $tab){
			position: absolute;
			top: 0;
			right: 0;
			width: $pc-head;
			height: $pc-head;
			background: $normal;
			display: block;
			z-index: 200;
			cursor: pointer;
			@media screen and (max-width: $phone){
				width: $sp-head;
				height: $sp-head;
			}
			&:before,
			&:after{
				content: '';
				width: 24px;
				height: 1px;
				background: #ccc;
				position: absolute;
				top: 44%;
				left: 50%;
				transform: translateX(-50%);
				transition: all 400ms $ease;
			}
			&:after{
				top: auto;
				bottom: 44%;
			}
			&.active{
				&:before{
					top: 50%;
					transform: translateX(-50%) rotate(45deg);
				}
				&:after{
					bottom: calc(50% - 1px);
					transform: translateX(-50%) rotate(-45deg);
				}
			}
		}
	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
 	}
}