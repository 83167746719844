#footMap{
	position: relative;
	iframe{
		width: 100%;
		height: 400px;
		vertical-align: bottom;
		filter: grayscale(1);
	}
}

.pagetop{
	background: darken($normal, 9%);
	color: $white;
	display: block;
	text-align: center;
	padding: 24px;
	transition: all 400ms $ease;
	&:hover{
		background: lighten($normal, 4%);
		color: $white;
	}
	@media screen and (max-width: $phone){
		padding: 16px 0;	
		font-size: .88rem;
	}
}

#site-footer{
	padding: 88px $pc-side;
	position: relative;
	z-index: 3;
	background: darken($normal, 6%);
	color: $white;
	@media screen and (max-width: $tab){
		padding: 72px $pc-side;	
	}
	@media screen and (max-width: $phone){
		padding: 48px $sp-side;	
	}
	a{
		color: $white;
	}
	.company{
		position: relative;
		figure{
			width: 32px;
			margin: 0 auto;
			img{
				width: 100%;
			}
		}
		dl{
			margin: 12px 0 0;
			text-align: center;
			dt{
				font-weight: 700;
				font-size: 1.24rem;
			}
			dd{
				font-size: .88rem;
			}
		}
	}
	.sns{
		@include flex();
		align-items: center;
		justify-content: center;
		margin: 32px 0;
		padding: 0 0 32px;
		border-bottom: rgba($white,.08) 1px solid;
		@media screen and (max-width: $phone){
			margin: 24px 0;
			padding: 0;
			border: none;
		}
		li{
			a{
				$size: 36px;
				width: $size;
				height: $size;
				border: rgba(#fff,.12) 1px solid;
				border-radius: 50%;
				@include flex();
				align-items: center;
				justify-content: center;
				font-size: .88rem;
				transition: all 200ms $ease;
				&:hover{
					background: #FFF;
					border-color: #FFF;
					color: $green;
					transform: scale(1.2);
				}
			}
		}
	}
	.fnavi{
		@include flex();
		align-items: center;
		justify-content: center;
		@media screen and (max-width: $phone){
			display: block;
			border-radius: 4px;
		}
		li{
			margin: 0 32px 3px 0;
			@media screen and (max-width: $phone){
				margin: 0 0 1px;
			}
			&:last-child{
				margin: 0;
			}
			a{
				font-size: .92rem;
				display: block;
				@media screen and (max-width: $phone){
					padding: 12px $sp-side;	
					background: rgba(#000,.12);
					position: relative;
					&:before{
						content: "\f105";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: absolute;
						top: 50%;
						right: $sp-side;
						font-size: .88rem;
						transform: translateY(-50%);
						opacity: .12;
					}
				}
				&:hover{
					color: $green;
					text-decoration: underline;
				}
			}
		}
	}
	.copy{
		display: block;
		margin: 48px 0 0;
		text-align: center;
		font-size: .8rem;
	}
}