@charset "utf-8";

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal: #2c3131;
$link:   #D02;
$border: #e8e8e8;
$gray:   #f5f5f7;
$green:  #01bbbd;
$blue:   #0699d0;
$orange: #fe5c18;
$white: #fdfffe;

$ease: cubic-bezier(0.65, 0, 0.35, 1);

/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head: 80px;
$sp-head: 60px;

$pc-side: 5.2vw;
$sp-side: 16px;

:root{
	font-size: 14.5px;
}

@mixin grd(){
    background: rgb(30,87,153);
    background: -moz-linear-gradient(-45deg,  rgba($green,1) 0%, rgba($blue,1) 100%);
    background: -webkit-linear-gradient(-45deg,  rgba($green,1) 0%,rgba($blue,1) 100%);
    background: linear-gradient(135deg,  rgba($green,1) 0%,rgba($blue,1) 100%);
}

@mixin ami($color, $size){
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15"><rect x="0" y="0" width="3" height="3" fill="#{$color}" stroke-width="0" /></svg>');
	background-size: $size;
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$white //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$white //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$white //ボディ背景
	);
}

@import "module/wire";
@import "common/header";
@import "common/footer";
@import "common/breadcrumb";
@import "page/index";
@import "page/about";
@import "page/business";
@import "page/access";
@import "page/recruit";
@import "page/contact";


.site-module{
	&.wrap{
		padding: calc(32px + 12.5vmin) $pc-side;
		@media screen and (max-width: $tab){
			padding: calc(32px + 7.2vmin) $pc-side;
		}
		@media screen and (max-width: $phone){
			padding: calc(32px + 3.6vh) $sp-side;
		}
		&.mid{
			padding: calc(40px + 6.25vmin) $pc-side;
			@media screen and (max-width: $tab){
                padding: calc(40px + 5vmin) $pc-side;
			}
			@media screen and (max-width: $phone){
				padding: calc(32px + 2.4vh) $sp-side;
			}
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		&.gray{
			background: $gray;
		}
	}
	h2,
	h3{
		&.large{
			font-size: 2.66rem;
			line-height: 1.5;
			letter-spacing: .04em;
			font-weight: 700;
			text-align: center;
			margin: 0 0 40px;
			@media screen and (max-width: $phone){
				font-size: 1.56rem;	
			}
			&:before{
				content: attr(data-eng);
				font-size: .88rem;
				letter-spacing: .24em;
				display: block;
				text-transform: uppercase;
				color: $green;
				margin: 0 0 4px;
			}
		}
		&.mid{
			font-size: 1.8rem;
			line-height: 1.5;
			font-weight: 700;
			text-align: center;
			margin: 0 0 48px;
			@media screen and (max-width: $phone){
				font-size: 1.2rem;
				margin: 0 0 32px;
			}
			&:before{
				content: attr(data-eng);
				font-size: .88rem;
				letter-spacing: .24em;
				display: block;
				text-transform: uppercase;
				color: $green;
				margin: 0 0 14px;
				@media screen and (max-width: $phone){
					margin: 0 0 12px;	
				}
			}
		}
	}
	.btnarea{
		margin: 32px 0 0;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		&.center{
			text-align: center;
		}
		a{
			@include grd();
            color: $white;
            padding: 10px 40px;
			border-radius: 999px;
			display: inline-block;
			font-size: 1.16rem;
			@media screen and (max-width: $phone){
				display: block;
				padding: 10px 0;
			}
            i{
                display: inline-block;
                margin: 0 8px 0 0;
            }
			&:hover{
				opacity: .8;
			}
		}
	}
	.tablestyle{
		border: $border 1px solid;
		border-collapse: collapse;
		width: 100%;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					&:last-child{
						border-bottom: none;
					}
				}
				th,td{
					text-align: left;
					vertical-align: middle;
					padding: 24px 20px;
					@media screen and (max-width: $phone){
						display: block;
						padding: 12px $sp-side;
					}
					dl.sanpai-no{
						width: 32%;
						display: inline-block;
						margin: 12px 0 0;
						@media screen and (max-width: $phone){
							width: auto;
							margin: 0;
							@include flex();
							justify-content: flex-start;
                            dt{
                                width: 100px;
                            }
                            dd{
                                width: calc(100% - 100px);
                                padding: 0 0 0 20px;
                            }
						}
						&:nth-child(-n+3){
							margin-top: 0;
						}
					}

				}
				th{
					width: 20%;
					background: $gray;
					@media screen and (max-width: $phone){
						width: auto;
						br{
							display: none;
						}
					}
				}
			}
		}
	}
}

#sidebnr{
	position: fixed;
	right: 24px;
	bottom: 24px;
	width: 260px;
	z-index: 100;
	box-shadow: 0 0 24px rgba($normal,.08);
	opacity: 0;
	transform: translateX(50%);
	pointer-events: none;
	transition: all 400ms $ease;
	transition-property: transform, opacity;
	@media screen and (max-width: $tab){
		width: 200px;	
	}
	&.active{
		opacity: 1;
		transform: translateX(0);
		pointer-events: auto;
	}
	a{
		display: block;
		&:hover{
			img{
				opacity: 1;
			}
		}
		img{
			width: 100%;
			height: auto;
			border-radius: 4px;
		}
	}
	#sidebnr-remove{
		position: absolute;
		top: -8px;
		right: -8px;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: darken($gray, 4%);
		@include flex();
		align-items: center;
		justify-content: center;
		transition: transform 200ms $ease;
		cursor: pointer;
		&:hover{
			transform: scale(1.2);
		}
		i{
			font-size: .88rem;
			color: $normal;
			opacity: .64;
		}
	}
}

#follower{
	position: fixed;
	top: 0;
	left: 0;
	width: 80px;
	height: 80px;
	z-index: 1000;
	background: $green;
	border-radius: 50%;
	pointer-events: none;
	opacity: 0;
	transform: scale(0);
	box-shadow: 0 0 32px rgba(#000,.12);
	transition: opacity 300ms 150ms $ease, transform 300ms 150ms $ease;
	@media screen and (max-width: $tab){
		display: none !important;
	}
	i{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("/images/common/slide_ui_2x.png") no-repeat center;
		background-size: 60px;
		transform: scale(0);
		transition: transform 300ms $ease;
	}
	&.active{
		opacity: 1;
		transform: scale(1);
		transition: opacity 300ms $ease, transform 300ms $ease;
		i{
			transform: scale(1);
			transition: transform 300ms 150ms $ease;
		}
	}
}
