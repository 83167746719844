.site-recruit{
	.localnav{
		@include flex();
		align-items: center;
		justify-content: center;
		background: darken($gray, 2%);
		padding: 24px;
		border-radius: 4px;
		@media screen and (max-width: $tab){
			justify-content: space-between;
		}
		@media screen and (max-width: $phone){
			display: block;
			padding: 12px 16px;
		}
		h2{
			font-size: 1.44rem;
			font-weight: 700;
			margin: 0 32px 0 0;
			@media screen and (max-width: $tab){
				width: 100%;
				text-align: center;
				margin: 0 0 12px;
			}
		}
		a{
			color: $normal;
			background: #FFF;
			padding: 12px 24px;
			border-radius: 4px;
			display: block;
			margin: 0 0 0 12px;
			box-shadow: 0 0 12px rgba($normal, .08);
			@media screen and (max-width: $tab){
				width: calc(50% - 4px);
				margin: 4px 0;
				font-size: .96rem;
				padding: 12px 15px;
				text-align: center;
			}
			@media screen and (max-width: $phone){
				width: auto;
				display: block;
				margin: 0 0 8px;
				text-align: left;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&:hover{
				background: $green;
				color: #FFF;
			}
			&:first-child{
				margin-left: 0;
			}
		}
	}
	.recruit{
		&__title{
			font-size: 1.44rem;
			font-weight: 700;
			border-bottom: $border 1px solid;
			padding: 0 0 12px;
			margin: 0 0 24px;
			position: relative;
			@media screen and (max-width: $phone){
				font-size: 1.24rem;
				line-height: 1.5;
			}
			&:before{
				content: '';
				background: $green;
				position: absolute;
				left: 0;
				bottom: -1px;
				width: 100px;
				height: 2px;
			}
		}
		&__box{
			@include flex();
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				display: block;
			}
			&__pic{
				width: 400px;
				@media screen and (max-width: $tab){
					width: 50%;
				}
				@media screen and (max-width: $phone){
					width: auto;
				}
				img{
					width: 100%;
				}
			}
			&__data{
				width: calc(100% - 400px);
				padding: 0 0 0 40px;
				@media screen and (max-width: $tab){
					width: 50%;
					padding: 0 0 0 24px;
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 24px 0 0;
				}
				dt{
					font-size: 1.24rem;
					font-weight: 700;
					margin: 0 0 12px;
				}
			}
		}
		&__points{
			background: $gray;
			border-radius: 4px;
			margin: 0 0 32px;
			padding: 24px;
			position: relative;
			@media screen and (max-width: $phone){
				padding: 15px;
			}
			&:before{
				content: 'ここがポイント';
				background: $green;
				color: #FFF;
				position: absolute;
				top: -32px;
				right: 16px;
				font-size: 1.12rem;
				font-weight: 700;
				padding: 8px 32px;
				border-radius: 999px;
				@media screen and (max-width: $tab){
					top: -28px;
				}
				@media screen and (max-width: $phone){
					font-size: 1rem;
					padding: 4px 20px;
				}
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 8px 0 8px;
				border-color: $green transparent transparent transparent;
				position: absolute;
				right: 56px;
				top: 12px;
				@media screen and (max-width: $tab){
					top: 16px;
				}
				@media screen and (max-width: $phone){
					right: 36px;
					top: 4px;
				}
			}
			li{
				padding: 20px;
				padding-left: 64px;
				background: #FFF;
				margin: 0 0 8px;
				position: relative;
				line-height: 1.6;
				i{
					position: absolute;
					top: 50%;
					left: 20px;
					transform: translateY(-50%);
					color: $green;
					font-size: 1.6rem;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
}