.site-index{
    .firstview{
		height: 100vh;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: center;
		overflow: hidden;
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			&__item{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url("/images/index/fv.jpg") no-repeat center bottom / cover;
				transform: scale(1.1);
				&.active{
					transform: scale(1);
					transition: transform 4000ms linear;
				}
			}
		}
		&__ttl{
			$shadow: darken(#101219, 0%);
			text-align: center;
			position: relative;
			z-index: 10;
			color: $white;
			font-weight: 700;
			text-shadow: 0 0 64px rgba($shadow, .64), 0 0 128px rgba($shadow, .24);
			user-select: none;
			dl{
				@media screen and (max-width: $phone){
					max-width: 88%;
					padding: 0 $sp-side;
					margin: 0 auto;
				}
				&.active{
					dt,
					dd{
						opacity: 1;
						transform: translateY(0);
					}
				}
				dt,dd{
					opacity: 0;
					transform: translateY(20%);
					transition: all 1000ms 400ms $ease;
					transition-property: opacity, transform;
				}
				dt{
					font-size: 5.2rem;
					margin: 0 0 24px;
					line-height: 1.1;
					text-transform: uppercase;
					@media screen and (max-width: $tab){
						font-size: 3.44rem;	
					}
					@media screen and (max-width: $phone){
						font-size: 2.96rem;	
					}
				}
				dd{
					font-size: 1.44rem;
					line-height: 1.72;
					transition-delay: 600ms;
					@media screen and (max-width: $tab){
						font-size: 1.24rem;	
					}
					@media screen and (max-width: $phone){
						font-size: 1.08rem;	
					}
				}
			}
		}
		&__sns{
			position: absolute;
			top: 50%;
			right: 40px;
			z-index: 2;
			transform: translateY(-50%);
			color: lighten($normal, 48%);
			background: #FFF;
			border-radius: 999px;
			padding: 24px 6px;
			box-shadow: 0 0 16px rgba($normal,.04);
			@media screen and (max-width: $tab){
				right: 20px;	
			}
			@media screen and (max-width: $phone){
				top: auto;
				right: auto;
				left: 50%;
				bottom: $sp-side * 1.5;
				padding: 8px 24px;
				transform: translate(-50%);
				@include flex();
				align-items: center;
			}
			&__ttl{
				@media screen and (min-width: calc(#{$phone} + 1px)){
					-webkit-writing-mode: vertical-rl;
					-moz-writing-mode: vertical-rl;
					-ms-writing-mode: tb-rl;
					writing-mode: vertical-rl;
					letter-spacing: .12em;
					font-size: .96rem;
				}
				@media screen and (max-width: $phone){
					font-size: 1rem;
					order: 2;
				}
			}
			&__list{
				text-align: center;
				font-size: .96;
				margin: 16px 0 0;
				color: $green;
				@media screen and (max-width: $phone){
					margin: 0 12px 0 0;
					order: 1;
				}
				li{
					font-size: 1rem;
					margin: 0 0 22px;
					i{
						display: block;
					}
					&:last-child{
						margin: 0;
					}
				}
			}
		}
		&__clone{
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 100%;
			background: $white;
			z-index: 100;
		}
    }
	
	.first{
		position: relative;
		overflow: hidden;
		.two{
			position: relative;
			margin: 56px 0 108px;
			@media screen and (max-width: $phone){
				margin: 32px 0 0;	
			}
			&:before{
				content: '';
				width: calc(28% + #{$pc-side});
				height: 100%;
				background: $green;
				position: absolute;
				top: 0;
				right: -$pc-side;
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			&__pic{
				width: 72%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;
				@media screen and (max-width: $phone){
					width: 100%;
					height: calc(calc(100vw - #{$sp-side * 2}) * 9 / 16);
					position: relative;
				}
				img{
					width: 100%;
					height: 120%;
					object-fit: cover;
					object-position: center;
					@media screen and (max-width: $phone){
						transform: translate(0,0) !important;
					}
				}
			}
			&__data{
				margin: 0 0 0 50%;
				padding: 88px 0;
				position: relative;
				z-index: 2;
				@media screen and (max-width: $tab){
					padding: 56px 0;
					margin: 0 0 0 40%;
				}
				@media screen and (max-width: $phone){
					margin: 32px 0 0;
					padding: 0;
				}
				dl{
					padding: 64px 56px;
					max-width: 580px;
					margin: 0 auto;
					background: $white;
					@media screen and (max-width: $tab){
						padding: 40px;	
					}
					@media screen and (max-width: $phone){
						padding: 0 $sp-side;
						background: none;
					}
					dt{
						font-size: 1.44rem;
						font-weight: 700;
						line-height: 1.66;
						margin: 0 0 20px;
						white-space: nowrap;
						@media screen and (max-width: $tab){
							font-size: 1.32rem;
							white-space: normal;
						}
						@media screen and (max-width: $phone){
							font-size: 1.24rem;
							br{
								display: none;
							}
						}
					}
					dd{
						font-size: 1.02rem;
						line-height: 1.9;
						text-align: justify;
						@media screen and (max-width: $phone){
							font-size: 1rem;	
						}
						&.contact{
							margin: 24px 0 0;
							a{
								color: $green;
								font-size: 1.08rem;
								font-weight: 700;
								position: relative;
								display: inline-block;
								@media screen and (max-width: $phone){
									background: $green;
									color: #FFF;
									padding: 12px 0;
									display: block;
									text-align: center;
								}
								&:before{
									content: '';
									width: 100%;
									height: 3px;
									background: $green;
									position: absolute;
									left: 0;
									bottom: -3px;
									border-radius: 999px;
									@media screen and (max-width: $phone){
										display: none;
									}
								}
								i{
									display: inline-block;
									margin: 0 8px 0 0;
								}
							}
						}
					}
				}
			}
			.mes{
				position: absolute;
				left: 8%;
				bottom: -180px;
				font-size: 20rem;
				font-weight: 700;
				line-height: 1;
				@include ami(rgba($green,.99), 7px);
				background-color: rgba($green,.02);
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				@media screen and (max-width: $tab){
					font-size: 16.6rem;	
				}
				@media screen and (max-width: $phone){
					font-size: 12rem;
					bottom: -40px;
				}
			}
		}
	}
	
	.second{
		position: relative;
		width: 100%;
		overflow: hidden;
		@media screen and (max-width: $tab){
			padding-right: $pc-side;	
			padding-left: $pc-side;	
		}
		.min{
			width: 56%;
			position: relative;
			z-index: 2;
			margin: 0 auto 8.8vmin;
			@media screen and (max-width: $tab){
				width: auto;
				padding: 0 $pc-side;
			}
			@media screen and (max-width: $phone){
				padding: 0 $sp-side;	
			}
			h2{
				font-weight: 700;
				font-size: 1.32rem;
				line-height: 1;
				margin: 0 0 24px -2px;
				@media screen and (max-width: $tab){
					margin-bottom: 20px;	
				}
				@media screen and (max-width: $phone){
					font-size: 1.12rem;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 6.64rem;
					display: inline-block;
					margin: 0 16px 0 0;
					@media screen and (max-width: $tab){
						font-size: 5.6rem;	
					}
					@media screen and (max-width: $phone){
						font-size: 2.4rem;	
					}
				}

			}
			.context{
				font-size: 1.28rem;
				@media screen and (max-width: $phone){
					font-size: 1rem;	
				}
			}
		}
		
		.buslist{
			&__list{
				@include flex();
				justify-content: flex-start;
				align-content: flex-start;
				margin: 0 0 0 -48px;
				@media screen and (max-width: $tab){
					margin: 0 0 0 -24px;	
				}
				@media screen and (max-width: $phone){
					display: block;
					margin: 0;
				}
				&._active{
					> li{
						pointer-events: auto;
						opacity: 1;
						transform: translate(0);
						@for $i from 1 through 20{
							&:nth-child(#{$i}){
								transition: all 400ms $i*80ms $ease;
								transition-property: transform, opacity;
							}
						}
					}
				}
				&__item{
					width: 33.3%;
					height: auto;
					padding: 0 0 0 48px;
					margin: 48px 0 0;
					position: relative;
					pointer-events: none;
					opacity: 0;
					transform: translateY(25%);
					@media screen and (max-width: $tab){
						padding: 0 0 0 24px;
						margin: 24px 0 0;
					}
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0 0 16px;
						padding: 0;
						&:last-child{
							margin: 0;
						}
					}
					&:nth-child(-n+3){
						margin-top: 0;
					}
					&__numb{
						position: absolute;
						top: -4px;
						left: -4px;
						background: $green;
						color: $white;
						font-size: 1.44rem;
						width: 48px;
						height: 48px;
						@include flex();
						align-items: center;
						justify-content: center;
						@media screen and (max-width: $phone){
							font-size: .92rem;
							width: 28px;
							height: 28px;
						}
					}
					a{
						display: block;
						height: 100%;
						padding: 32px;
						background: $gray;
						border: $gray 4px solid;
						color: $normal;
						position: relative;
						transition: all 300ms $ease;
						transition-property: background;
						@media screen and (max-width: $tab){
							padding: 20px;	
						}
						@media screen and (max-width: $phone){
							@include flex();
							align-items: center;
							padding: $sp-side;
						}
						&:hover{
							background: $white;
						}
						figure{
							width: 72px;
							margin: 0 auto 24px;
							@media screen and (max-width: $tab){
								width: 32%;	
							}
							@media screen and (max-width: $phone){
								width: 48px;
								margin: 0;
							}
							img{
								width: 100%;
								height: auto;
							}
						}
						dl{
							@media screen and (max-width: $phone){
								width: calc(100% - 48px);
								padding: 0 0 0 24px;
							}
							dt{
								font-size: 1.24rem;
								font-weight: 700;
								text-align: center;
								margin: 0 0 6px;
								@media screen and (max-width: $tab){
									font-size: 1.08rem;	
								}
								@media screen and (max-width: $phone){
									text-align: left;	
									margin: 0;
								}
							}
							dd{
								line-height: 1.66;
								@media screen and (max-width: $phone){
									font-size: .92rem;	
								}
							}
						}
					}
				}
			}
		}
	}
	
	.third{
		position: relative;
		overflow: hidden;
		.ami{
			content: '';
			width: 32vh;
			height: 32vh;
			position: absolute;
			@include ami(rgba($green,.99), 8px);
			z-index: -1;
			@media screen and (max-width: $phone){
				width: 20vh;
				height: 20vh;
				@include ami(rgba($green,.99), 6px);
			}
			&.left{
				top: 64px;
				left: 0;
				@media screen and (max-width: $phone){
					top: 0;
				}
			}
			&.right{
				bottom: 64px;
				right: 0;
			}
		}
		.two{
			background: $gray;
			margin: 0 2.4vw;
			@include flex();
			@media screen and (max-width: $tab){
				display: block;
				margin: 0;
				background: none;
			}
			&__item{
				width: 50%;
				padding: 48px;
				@media screen and (max-width: $tab){
					width: auto;
					margin: 0 0 40px;
					background: $gray;
					padding: 40px;
					@include flex();
					align-items: center;
					justify-content: center;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
				@media screen and (max-width: $phone){
					padding: $sp-side;
					margin: 0 0 20px;
				}
				&:last-child{
					border-left: $white 8px solid;
				}
				figure{
					width: 240px;
					margin: 0 auto 16px;
					@media screen and (max-width: $tab){
						width: 150px;
					}
					@media screen and (max-width: $phone){
						width: 80px;
						margin: 0;
					}
					img{
						width: 100%;
					}
				}
				h4{
					font-size: 1.44rem;
					font-weight: 700;
					text-align: center;
					margin: 0 0 16px;
					@media screen and (max-width: $tab){
						width: calc(100% - 150px);
						margin: 0;
						padding-left: 40px;
						text-align: left;
					}
					@media screen and (max-width: $phone){
						width: calc(100% - 80px);
						padding-left: 24px;
						font-size: 1.12rem;
						margin: 0;
					}
				}
				.pref{
					font-size: .92rem;
					max-width: 480px;
					margin: 0 auto;
					text-align: justify;
					@media screen and (max-width: $tab){
						min-width: 1px;
						margin: 24px 0 0;
						width: 100%;
						max-width: 100%;
					}
					@media screen and (max-width: $phone){
						padding: 0 $sp-side;
						margin: 8px 0 0;
						font-size: .88rem;
					}
				}
			}
		}
	}
	.recruit{
		position: relative;
		padding: 144px $pc-side;
		overflow: hidden;
		color: $white;
		@media screen and (max-width: $tab){
			padding: 72px $pc-side;	
		}
		@media screen and (max-width: $phone){
			padding: calc(32px + 3.2vh) $sp-side*2;	
		}
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			background: darken($normal, 20%);
			opacity: .48;
			position: absolute;
			top: 0;
			left: 0;
		}
		.display{
			@include flex();
			align-items: center;
			justify-content: center;
		}
		&__bg{
			position: absolute;
			top: -20%;
			left: 0;
			width: 100%;
			height: 140%;
			background: url("/images/common/kussion_bg.jpg") no-repeat center bottom / cover;
			z-index: -1;
		}
		&__content{
			h2{
				font-size: 1.2rem;
				font-weight: 700;
				line-height: 1;
				text-align: center;
				letter-spacing: .12em;
				margin: 0 0 24px;
				@media screen and (max-width: $phone){
					font-size: 1rem;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 3.2rem;
					display: block;
					margin: 0 0 8px;
					@media screen and (max-width: $phone){
						font-size: 1.92rem;	
					}
				}
			}
			&__p{
				font-size: 1.08rem;
				text-align: center;
			}
			&__list{
				@include flex();
				justify-content: center;
				text-align: center;
				margin: 32px 0 0;
				@media screen and (max-width: $phone){
					display: block;
					margin: 24px 0 0;
				}
				li{
					margin: 0 20px 0 0;
					min-width: 250px;
					&:last-child{
						margin: 0;
					}
					a{
						display: block;
						border: $white 1px solid;
						color: $white;
						font-size: 1.24rem;
						padding: 10px 32px;
						position: relative;
						transition: all 200ms $ease;
						i{
							position: absolute;
							top: 50%;
							right: 20px;
							transform: translateY(-50%);
							font-size: .8rem;
							transition: all 200ms $ease;
						}
						&:hover{
							background: $white;
							color: $normal;
							i{
								right: 15px;
							}
						}
					}
				}
			}
		}
	}

}