.site-about{
	.greet{
		background: $gray;
		padding: 48px 0;
		padding-top: calc(1.3rem + 40px);
		margin: 1.3rem 0 0;
		position: relative;
		@media screen and (max-width: $phone){
			padding: 24px 0;
			padding-top: 32px;
		}
		&__h{
			@include min();
			font-size: 2.6rem;
			font-weight: 700;
			text-align: center;
			letter-spacing: .16em;
			padding: 0 32px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			@media screen and (max-width: $phone){
				font-size: 1.56rem;
				white-space: nowrap;
			}
		}
		&__context{
			font-size: 1.12rem;
			width: 72%;
			margin: 0 auto;
			line-height: 2.1;
			text-align: justify;
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 $sp-side*1.5;
				font-size: .96rem;
				line-height: 1.9;
			}
		}
	}
	.tablestyle{
		a{
			color: darken($green, 2%);
		}
		i{
			display: inline-block;
			margin: 0 5px 0 0;
		}
	}
	.pic{
		margin: 56px 0 0;
		@media screen and (max-width: $phone){
			margin: 32px 0 0;
		}
		img{
			width: 100%;
		}
	}
	.gallery{
		margin: 0 0 0 -32px;
		@include flex();
		justify-content: flex-start;
		@media screen and (max-width: $phone){
			margin: 0;	
			justify-content: space-between;
		}
		> li{
			width: 25%;
			padding: 0 0 0 40px;
			margin: 48px 0 0;
			&:nth-child(-n+4){
				margin-top: 0;
			}
			@media screen and (max-width: $phone){
				width: 48%;
				margin: 8% 0 0;
				padding: 0;
				&:nth-child(-n+4){
					margin-top: 8%;
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
			}
			figure{
				img{
					width: 100%;
				}
				figcaption{
					padding: 15px 0 0;
					font-size: .88rem;
					text-align: center;
					@media screen and (max-width: $phone){
						padding: 8px 0 0;	
					}
				}
			}
		}
	}
}